const Home = () => {
  return (
      <div>
        <h3>Home</h3>
        <p>This is my new website. I deleted the old one because it was more of a resume extension while I was trying to get a job.</p>
        <p>I will be adding more pages with more projects when I have the time. Currently there is only one spotify project available.</p>
      </div>
  );
}
 
export default Home;