import React from "react";
import * as Store from "./store"
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux"

import { CommandBar, ICommandBarItemProps, Stack } from "@fluentui/react";
import { initializeIcons } from '@fluentui/font-icons-mdl2';

import Home from "./components/Home";
import Spotify from "./components/Spotify";

initializeIcons();

const _items: ICommandBarItemProps[] = [
  {
    key: "home",
    text: "Home",
    href: "/",
  },
  {
    key: "spotify",
    text: "Spotify",
    href: "/spotify"
  },
];

type AppProps = Store.ApplicationState & typeof Store.actionCreators.spotify

const App: React.FunctionComponent<AppProps> = props => {
  return (
    <div>
      <Stack horizontal horizontalAlign="center"><CommandBar items={_items}/></Stack>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" render={() => 
            <Home/>}
          />
          <Route path="/spotify/:code?" component={() => 
            <Spotify {...props} />}
          />
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default connect(
  (state: Store.ApplicationState) => state,
  {
    ...Store.actionCreators.spotify
  }
)(App as any);