export interface AppThunkAction<TAction, TState> {
    (dispatch: (action: TAction) => void, getState: () => TState): void;
}

export enum actions {
    Login = "Login",
    RequestAccessToken = "RequestAccessToken",
    RequestAccessTokenSuccess = "RequestAccessTokenSuccess",
    RequestAccessTokenError = "RequestAccessTokenError",
    RefreshAccessToken = "RefreshAccessToken",
    RefreshAccessTokenSuccess = "RefreshAccessTokenSuccess",
    RefreshAccessTokenError = "RefreshAccessTokenError",
    GetDivisiveClustering = "GetDivisiveClustering",
    GetDivisiveClusteringSuccess = "GetDivisiveClusteringSuccess",
    GetDivisiveClusteringError = "GetDivisiveClusteringError",
    GetSongInfo = "GetSongInfo",
    GetSongInfoSuccess = "GetSongInfoSuccess",
    GetSongInfoError = "GetSongInfoError"
}