import * as SpotifyActions from "./spotify/tasks"
import * as SpotifyReducer from "./spotify/reducer"

import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk'; 
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

export interface ApplicationState {
    spotify: SpotifyReducer.SpotifyState
}

const spotifyPersistConfig = {
    key: 'spotify',
    storage: storage,
    blacklist: ['gettingSongInfo', 'error']
}

export const reducers = combineReducers<ApplicationState>({
    // @ts-ignore
    spotify: persistReducer(spotifyPersistConfig, SpotifyReducer.SpotifyReducer),
})

export const actionCreators = {
    spotify: SpotifyActions.actionCreators
}

const persistConfig = {
    key: 'root',
    storage: storage,
    Statereconciler: autoMergeLevel2,
    blacklist: ["spotify"]
};

const myPersistReducer = persistReducer(persistConfig, reducers)

const composeEnhancers  = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(myPersistReducer, composeEnhancers(applyMiddleware(thunk)))

export const persistor = persistStore(store)
export default store
