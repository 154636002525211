import { KnownAction } from "./actions"
import { Reducer } from "redux"
import { actions } from "../actionTypes"
import { ISongInfo } from "../spotifyClient"

export interface SpotifyState {
    codeVerifier?: string
    spotifyState?: string,
    accessToken?: string,
    refreshToken?: string,
    expirationTime?: Number,
    clusters?: {},
    loading?: boolean,
    error?: string
    songInfo?: {[key: string]: ISongInfo}
    gettingSongInfo?: boolean
}

let DefaultSpotifyState = {} as SpotifyState

const SpotifyReducer: Reducer<SpotifyState, KnownAction> = (state: SpotifyState | undefined, incomingAction: KnownAction): SpotifyState => {
    if (state === undefined || state === {} as SpotifyState) {
        return DefaultSpotifyState
    }
    
    const action = incomingAction as KnownAction
    switch(action.type) {
        case actions.Login:
            return { ...state, codeVerifier: action.codeVerification, spotifyState: action.spotifyState}
        case actions.RequestAccessToken:
            return state
        case actions.RequestAccessTokenSuccess:
            return { ...state, accessToken: action.accessToken, refreshToken: action.refreshToken, expirationTime: action.expirationTime}
        case actions.RefreshAccessTokenSuccess:
            return { ...state, accessToken: action.accessToken, refreshToken: action.refreshToken, expirationTime: action.expirationTime}
        case actions.RefreshAccessTokenError:
            return { ...state, accessToken: undefined, refreshToken: undefined, expirationTime: undefined}
        case actions.GetDivisiveClustering:
            return { ...state, loading: true }
        case actions.GetDivisiveClusteringSuccess:
            return { ...state, loading: false, clusters: action.clusters } 
        case actions.GetDivisiveClusteringError:
            return { ...state, loading: false, error: "Error Getting Divisive Clustering: " + action.error}
        case actions.GetSongInfo:
            return { ...state, gettingSongInfo: true }
        case actions.GetSongInfoSuccess:
            return { ...state, gettingSongInfo: false, songInfo: action.songInfo }
        case actions.GetSongInfoError:
            return { ...state, gettingSongInfo: false, error: "Error Getting Song Info" }
        default: 
            return state
    }
}
export { DefaultSpotifyState, SpotifyReducer}
