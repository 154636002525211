import { Dispatch } from "react"

import { ApplicationState } from "../index"
import { KnownAction } from "./actions"
import { ISongInfo, SpotifyClient } from "../spotifyClient"
import { actions } from "../actionTypes"

import { randomBytes, base64url } from "../spotifyClient"

export const actionCreators = {
    Login: () => (dispatch: Dispatch<KnownAction>, getState: () => ApplicationState) => {
        const spotifyState = getState().spotify
        if (spotifyState.expirationTime && spotifyState.refreshToken) {
            let spotifyClient = new SpotifyClient()
            spotifyClient.refreshAccessToken(spotifyState.refreshToken)
            .then((response: any) => {
                dispatch({
                    type: actions.RefreshAccessTokenSuccess, 
                    accessToken: response.access_token,
                    refreshToken: response.refresh_token, 
                    expirationTime: Date.now() + (response.expires_in * 1000)})
            })
            .catch(error => {
                dispatch({type: actions.RefreshAccessTokenError})
            })
        }
        else {
            const spotifyState = base64url(randomBytes(96))
            const code_verifier = base64url(randomBytes(96))

            dispatch({type: actions.Login, codeVerification: code_verifier, spotifyState: spotifyState})

            let spotifyClient = new SpotifyClient()
            spotifyClient.login(code_verifier, spotifyState)
            .then(data => {
                console.log("Successful login call")
            })
            .catch(error => {
                console.log("Error login call", error)
            })
        }
    },

    RequestAccessToken: (code) => (dispatch: Dispatch<KnownAction>, getState: () => ApplicationState) => {
        const { codeVerifier } = getState().spotify

        let spotifyClient = new SpotifyClient()
        dispatch({type: actions.RequestAccessToken})
        if (codeVerifier)
            spotifyClient.requestAccessToken(code, codeVerifier)
            .then((response: any) => {
                dispatch({
                    type: actions.RequestAccessTokenSuccess, 
                    accessToken: response.access_token,
                    refreshToken: response.refresh_token, 
                    expirationTime: Date.now() + (response.expires_in * 1000)})
            })
            .catch(error => {
                dispatch({type: actions.RequestAccessTokenError})
            })
        else    
            throw Error("RequestAccessToken Error: codeVerifier undefined")
    },

    GetDivisiveClustering: () => (dispatch: Dispatch<KnownAction>, getState: () => ApplicationState) => {
        const { accessToken } = getState().spotify

        let spotifyClient = new SpotifyClient()
        dispatch({type: actions.GetDivisiveClustering})
        if (accessToken)
            spotifyClient.getDivisiveClustering(accessToken)
            .then((response: any) => {
                dispatch({
                    type: actions.GetDivisiveClusteringSuccess,
                    clusters: response
                })
            })
            .catch(error => {
                dispatch({type: actions.GetDivisiveClusteringError, error})
            })
        else    
            throw Error("GetDivisiveClustering Error: accessToken undefined")
    },
    GetSongInfo: (songIds: string[]) => (dispatch: Dispatch<KnownAction>, getState: () => ApplicationState) => {
        const { accessToken } = getState().spotify

        let spotifyClient = new SpotifyClient()
        dispatch({type: actions.GetSongInfo})
        if (accessToken) {
            let songInfoPromises: Promise<{[key: string]: ISongInfo}>[] = [] as Promise<{[key: string]: ISongInfo}>[]
            for (let i = 0; i < songIds.length; i += 50) {
                const subset = songIds.slice(i, i + 50)
                songInfoPromises.push(spotifyClient.getSongInfo(accessToken, subset))
            }
            Promise.all(songInfoPromises).then((results: {[key: string]: ISongInfo}[]) => {
                let songInfo: {[key: string]: ISongInfo} = {} as {[key: string]: ISongInfo}
                results.forEach((result: {[key: string]: ISongInfo}, index) => {
                    Object.assign(songInfo, result)
                })
                dispatch({
                    type: actions.GetSongInfoSuccess,
                    songInfo: songInfo
                }) 
            }).catch(error => {
                dispatch({
                    type: actions.GetSongInfoError
                })
            })
        }
        else    
            throw Error("GetDivisiveClustering Error: codeVerifier undefined")
    }
}