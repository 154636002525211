import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory  } from 'react-router-dom'

import * as tasks from "../store/spotify/tasks"

import { IResponse, ISong, ISongInfo } from "../store/spotifyClient"

import SpotifyPlayer from 'react-spotify-web-playback';
import { DefaultButton, Text, Spinner, IGroup, IColumn, SelectionMode, DetailsList, IconButton, DetailsListLayoutMode } from "@fluentui/react";

type IItem = ISongInfo & {
  song:  ISong,
  group: IGroup,
  id: string,
  distance: number,
}

const columnNames = ["Group(count)", "name", "artists", "album","distance", "play"]
 
const Spotify: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const isLoggedIn = Date.now() < useSelector(state => state.spotify?.expirationTime) || false
  const isLoading = useSelector(state => state.spotify?.loading) || false
  const clusters: IResponse = useSelector(state => state.spotify?.clusters)
  const songInfo: {[key: string]: ISongInfo} = useSelector(state => state.spotify?.songInfo)
  const gettingSongInfo: boolean = useSelector(state => state.spotify?.gettingSongInfo)
  const error: string = useSelector(state => state.spotify?.error)
  const authToken: string = useSelector(state => state.spotify?.accessToken)

  const [groups, setGroups] = useState([] as IGroup[])
  const [columns, setColumns] = useState([] as IColumn[])
  const [items, setItems] = useState([] as IItem[])
  const [nowPlaying, setNowPlaying] = useState([] as string[])

  const history = useHistory()
  const queryParams = new URLSearchParams(useLocation().search);
  const code = queryParams.get('code');

  React.useEffect(() => {
    if (code) {
      queryParams.delete('code')
      queryParams.delete('state')
      history.replace({
        search: queryParams.toString(),
      })
      dispatch(tasks.actionCreators.RequestAccessToken(code))
    }
  }, [code])

  React.useEffect(() => {
    if (!error && clusters) {
      if (isLoggedIn && !songInfo && !gettingSongInfo) {
        dispatch(tasks.actionCreators.GetSongInfo(Object.keys(clusters)))
      }
    } 
  }, [clusters])

  React.useEffect(() => {
    if (!error && songInfo) {
      const cols = columnNames.map(
        (key: string): IColumn => ({
          key: key,
          name: key,
          ariaLabel: key,
          fieldName: key,
          minWidth: ["Group(count)", "play"].includes(key) ? 100 : 150,
          maxWidth: ["Group(count)", "play"].includes(key) ? 100 : 400,
          isResizable: !["Group(count)", "play"].includes(key)
        }),
      );
      setColumns(cols)

      let labelGroups: IGroup[] = []
      let songItems: IItem[] = []
      let labelIndex = 0
      for (const [key, value] of Object.entries(clusters).sort((a, b) => {
        if (a[1].label === b[1].label) {
          const distanceField = "distance_ " + a[1].label
          return a[1][distanceField] - b[1][distanceField]
        }
        return a[1].label - b[1].label
      })) {
        // If label not in groups 
        if (!labelGroups.some(l => l.key === value.label.toString())) {
          const labelCount: number = Object.values(clusters).filter((c: ISong) => c.label === value.label).length
          // Add to groups
          labelGroups.push({
            key: value.label.toString(),
            name: value.label.toString(),
            startIndex: labelIndex,
            isCollapsed: false,
            count: labelCount
          })
          labelIndex += labelCount
        }
        const distanceField = "distance_ " + value.label
        const info = songInfo[key]
        songItems.push({
          song: value,
          group: labelGroups[value.label],
          id: key,
          distance: value[distanceField],
          ...info
        })
      }

      setGroups(labelGroups)
      setItems(songItems)
    }
  },[songInfo])

  const onRenderColumn = (item: IItem, index?: number, column?: IColumn) => {
    switch (column?.key) {
      case 'Group(count)':
        return <img src={item.image} width={50} height={50}/>;
      case 'play':
        return <IconButton onClick={() => setNowPlaying(["spotify:track:" + item.id])} iconProps={{iconName: "Play"}}/>
      default:
        return <span>{column?.name ? item[column.name] : "NA"}</span>;
    }
  }

  return (
      <React.Fragment>
        <h3>Spotify</h3>
        <p>This project uses <a target="_blank" href="https://www.geeksforgeeks.org/ml-hierarchical-clustering-agglomerative-and-divisive-clustering/">Divisive Clustering</a> and <a target="_blank" href="https://en.wikipedia.org/wiki/K-means_clustering">K-means</a> in order to divide your spotify music library into distinct groups.</p>
        { isLoading && <Spinner label="Loading"/>}
        { gettingSongInfo && <Spinner label="Getting Song Info"/>}
        { error && <Text>Error: {error}</Text>}
        { !isLoggedIn && 
          <div>
            <p>Plese login to spotify</p>
            <DefaultButton onClick={() => dispatch(tasks.actionCreators.Login())} text="Login"/> 
          </div>
        }
        { !isLoading && isLoggedIn && !clusters && <div>
            <p>Now start the clustering process. This may take awhile.</p>
            <DefaultButton onClick={() => dispatch(tasks.actionCreators.GetDivisiveClustering())} text="Cluster"/> 
          </div>
        }
        { !isLoading  && clusters && isLoggedIn &&
          <div>
            <p>Here is the output groups. The distance column is the distance from that groups' "center". Also you can play the songs!</p>
            <DetailsList
              items={items}
              columns={columns}
              // eslint-disable-next-line react/jsx-no-bind
              //onRenderCell={onRenderCell}
              groups={groups}
              selectionMode={SelectionMode.none}
              onRenderItemColumn={onRenderColumn}
              layoutMode={DetailsListLayoutMode.justified}
            />
          </div>
        }
        { isLoggedIn && authToken && nowPlaying.length && 
          <div style={{ 
            position: "fixed",
            bottom: "0",
            left: "0",
            width: "100%",
          }}>
            <SpotifyPlayer
              token={authToken}
              uris={nowPlaying}
              autoPlay={true}
            />
          </div>
        }
      </React.Fragment>
  )
}
 
export default Spotify;