import env from "react-dotenv";

const callback = env.CALLBACK

export interface ISong {
    distance_0: number,
    distance_1: number,
    distance_2: number,
    distance_3: number,
    distance_4: number,
    distance_5: number,
    distance_6: number,
    distance_7: number,
    distance_8: number,
    acousticness: number,
    danceability: number,
    energy: number,
    instrumentalness: number,
    key: number,
    label: number,
    liveness: number,
    loudness: number,
    mode: number,
    speechiness: number,
    tempo: number,
    time_signature: number,
    valence: number,
  }
  
export interface IResponse {
    [key: string]: ISong
}

export interface ISongInfo {
    image: string,
    artists: string[],
    album: string
    name: string
}

async function fetchJSON(input, init) {
    const response = await fetch(input, init)
    const body = await response.json()
    if (!response.ok) {
      throw new Error(response.toString())
    }
    return body
}

export const base64url = (bytes) => {
    return btoa(String.fromCharCode(...bytes))
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
}

async function generateCodeChallenge(code_verifier) {
    const codeVerifierBytes = new TextEncoder().encode(code_verifier)
    const hashBuffer = await crypto.subtle.digest('SHA-256', codeVerifierBytes)
    return base64url(new Uint8Array(hashBuffer))
}

export function randomBytes(size) {
    return crypto.getRandomValues(new Uint8Array(size))
}

export class SpotifyClient {
    async login (code_verifier: string, spotifyState: string) {
        var scope = 'user-read-private user-read-email user-library-read streaming user-read-playback-state user-modify-playback-state';

        const params = new URLSearchParams({
            client_id: env.SPOTIFY_CLIENT_ID,
            response_type: 'code',
            redirect_uri: callback,
            code_challenge_method: 'S256',
            code_challenge: await generateCodeChallenge(code_verifier),
            state: spotifyState,
            scope: scope,
        })
        const url = `https://accounts.spotify.com/authorize?${params}`
        
        window.location.href = url
    }

    async requestAccessToken (code: string, code_verifier: string) {
        const url = 'https://accounts.spotify.com/api/token'

        const params = {
            grant_type: 'authorization_code',
            code: code,
            redirect_uri: callback,
            code_verifier: code_verifier,
        }

        const response = await fetchJSON(url, {
            method: 'POST',
            body: new URLSearchParams({
                client_id: env.SPOTIFY_CLIENT_ID,
                ...params,
            }),
        })

        return response
    }

    async refreshAccessToken (refresh_token: string) {
        const url = 'https://accounts.spotify.com/api/token'

        const params = {
            grant_type: 'refresh_token',
            refresh_token: refresh_token
        }

        const response = await fetchJSON(url, {
            method: 'POST',
            body: new URLSearchParams({
                client_id: env.SPOTIFY_CLIENT_ID,
                ...params,
            }),
        })

        return response
    }

    async getDivisiveClustering (auth_token: string): Promise<IResponse> {
        const url = "https://spotifywebsite.azurewebsites.net/api/Clustering?authToken=" + auth_token

        const response = await fetch(url)
        const jsonResponse = await response.json()
        return jsonResponse
    }

    async getSongInfo (auth_token: string, songIds: string[]): Promise<{[key: string]: ISongInfo}> {
        const url = "https://api.spotify.com/v1/tracks?ids=" + songIds.join(",")

        const response = await fetchJSON(url, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${auth_token}`
            }
        })
        
        const songInfo = {} as {[key: string]: ISongInfo}
        response.tracks.forEach(info => {
            songInfo[info.id] = {
                album: info.album.name ?? "",
                image: info.album.images?.[0]?.url ?? "",
                artists: info.artists.map(a => a.name) ?? "",
                name: info.name ?? ""
            }
        })
        return songInfo
    }
}